<template>
  <div class="app-container">
    <el-main>
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
        <el-form-item label="模版名称" prop="name">
          <el-input
              style="width: 100%"
              v-model="queryParams.name"
              placeholder="请输入模版名称"
              clearable
              @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="项目类型" prop="projectTypeId">
          <el-select style="width: 100%" v-model="queryParams.projectTypeId" filterable clearable placeholder="请选择项目类型">
            <el-option
                v-for="item in productTypeOptions"
                :key="item.id"
                :label="item.label"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleQuery">搜索</el-button>
          <el-button type="warning" @click="resetQuery">刷新</el-button>
        </el-form-item>
      </el-form>

      <el-row :gutter="10" class="mb8" justify="end">
        <el-col :span="1.5">
          <el-button
              type="primary"
              @click="handleAdd"
          >新增</el-button>
        </el-col>
      </el-row>

      <el-table v-loading="loading" :data="contractTemplateList" @selection-change="handleSelectionChange">
        <el-table-column width="100">
          <template #default="scope">
            <div style="display:flex;align-items:center;justify-content:center">
              <el-image style="width: 49px; height: 49px;" :src="backImgUrl" fit="contain"/>
              <el-image style="width: 23px; height: 22px;position:absolute" :src="imgUrl" fit="contain"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12">
                <span class="title">{{ scope.row.name }}</span>
              </el-col>
              <el-col :span="12" style="text-align:right">
                <el-tooltip content="合同详情">
                  <el-icon :size="20" style="margin-right:10px" @click="showContract(scope.row)">
                    <Document color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="下载">
                  <el-icon :size="20" style="margin-right:10px" @click="handleDownload(scope.row)">
                    <Download color="#00B7FF" />
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="12">
                <el-descriptions :column="1">
                  <el-descriptions-item label="项目类型：">{{ scope.row.projectType.name }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="12">
                <el-descriptions :column="1">
                  <el-descriptions-item label="项目数量：">{{ scope.row.projectCount }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          v-model:page="queryParams.pageNum"
          v-model:limit="queryParams.pageSize"
          @pagination="getList"
      />

      <!-- 添加或修改合同模版对话框 -->
      <el-dialog :title="title" v-model="open" width="500px">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="模版名称" prop="name">
            <el-input style="width: 100%" maxlength="50" v-model="form.name" placeholder="请输入模版名称" />
          </el-form-item>
          <el-form-item label="项目类型" prop="projectTypeId">
            <el-select style="width: 100%" v-model="form.projectTypeId" filterable placeholder="请选择项目类型">
              <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="上传" prop="fileList">
            <file-upload :value="form.fileList" :limit="1" :is-show-tip="true" @update:file-list="handleUploadFile" :file-type="fileTypes"></file-upload>
          </el-form-item>
        </el-form>
        <template #footer>
           <span class="dialog-footer">
             <el-button @click="cancel">取 消</el-button>
             <el-button type="primary" @click="submitForm" :disabled="submitting">确 定</el-button>
           </span>
        </template>
      </el-dialog>
      <file-preview :filepath="docUrl" v-if="openDetail" v-model:visible="openDetail"/>
    </el-main>

  </div>
</template>

<script>
import { listContractTemplate, getContractTemplate, delContractTemplate, addContractTemplate, updateContractTemplate } from "@/api/contract_template";
import { isResOK } from "@/api/response";
import pagination from "@/components/Pagination/index.vue";
import {getProjectTypeOptions} from "@/api/project_type";
import {Document, Download} from "@element-plus/icons-vue";
import FileUpload from "@/components/FileUpload/index.vue";
import mammoth from "mammoth";
import axios from "axios";
const docxPre = require('docx-preview')
import FilePreview from '@/components/FilePreview/FilePreview.vue'
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";

let baseURL=process.env.VUE_APP_BASE_API
export default {
  name: "ContractTemplate",
  components:{FileUpload, Document, Download, pagination,FilePreview},
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 合同模版表格数据
      contractTemplateList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openDetail: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        projectTypeId: null,
        projectCount: null,
      },
      // 表单参数
      form: {
        field101: null,
      },
      // 表单校验
      rules: {
        name: [
          { required: true, message: "模版名称不能为空", trigger: "blur" }
        ],
        projectTypeId: [
          { required: true, message: "项目类型不能为空", trigger: "blur" }
        ],
        fileList: [
          { required: true, message: "不能为空", trigger: "blur" }
        ]
      },
      productTypeOptions:[],
      imgUrl: require(`/src/assets/contract_template.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
      docUrl: '',
      vHtml: undefined,
       fileTypes: [".doc",".docx"],
      showDoc: false,
      fileUrl: '',
      submitting: false,
    };
  },
  created() {
    this.getProductTypeOptions();
    this.getList()
    this.fetchCurrentUser()
  },
  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },
    handleUploadFile(fileList){
      this.form.fileList = fileList
    },
    getProductTypeOptions(){
      getProjectTypeOptions().then(res =>{
        if (isResOK(res)) {
          this.productTypeOptions = res.data
        }
      })
    },
    /** 查询合同模版列表 */
    getList(params) {
      if (params !== null && undefined !== params){
        this.queryParams.pageNum = params.page
        this.queryParams.pageSize = params.limit
      }
      this.loading = true;
      listContractTemplate(this.queryParams).then(response => {
        if (isResOK(response)){
          this.contractTemplateList = response.rows;
          this.total = response.total;
        }
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    cancelDetail(){
      this.openDetail = false;
      this.docUrl = '';
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        projectTypeId: null,
        projectCount: null,
        createTime: null,
        updateTime: null,
        fileList: []
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加合同模版";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getContractTemplate(id).then(response => {
        if (isResOK(response)){
          this.form = response.data;
        }
        this.open = true;
        this.title = "修改合同模版";
      });
    },
    handleDownload(row){
      this.$download.name(row.fileList[0].storageBlob.newFilename,false,true)
    },
    /** 提交按钮 */
    submitForm() {
      this.submitting = true
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateContractTemplate(this.form).then(response => {
              if (isResOK(response)){
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.open = false;
                this.getList();
                this.submitting = false
              }
            });
          } else {
            addContractTemplate(this.form).then(response => {
              if (isResOK(response)){
                this.$message({
                  message: '新增成功',
                  type: 'success',
                })
                this.open = false;
                this.getList();
                this.submitting = false
              }
            });
          }
          this.submitting = false
        } else {
          this.submitting = false
        }
      }).catch(() => {
        this.submitting = false
      });
      this.getProductTypeOptions()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$messageBox.confirm('是否确认删除合同模版编号为"' + ids + '"的数据项？', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'},).then(() => {
        delContractTemplate(ids).then((res) => {
          if (isResOK(res)) {
            this.$message({message: '删除成功', type: 'success',})
          }
          this.getList()
        })
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport(row) {
      // this.download('common/download', nil, `contract_template_${new Date().getTime()}.xlsx`)

    },
    showContract(row){
      this.docUrl = baseURL + row.fileList[0].storageBlob.newFilename;
      this.openDetail = true
    },
    showView(filePath) {
      let that = this
      let type = filePath.split('.')[filePath.split('.').length - 1]
      if (type === 'jpg' || type === 'png' || type === 'jpeg') {
        // that.images = filePath
        // that.showImg = true
      } else if (type === 'pdf') {
        // that.loadPdfHandler()//重置pdf第一页展示
        // that.pdfPath = filePath
        // that.showPdf = true
      } else if (type === 'doc') {//word预览
        that.fileUrl = 'https://view.officeapps.live.com/op/view.aspx?src=' + filePath
        console.log(that.fileUrl)
        that.showDoc = true
      } else if (type === 'docx') {//word预览
        that.showDoc = true
        that.previewWord(filePath)
      }
    },
    // 后端返回二进制流
    previewWord(filePath) {
      let that = this
      // 这里需要提起打开弹窗，因为有时很找不到word的ref 会报错
      axios({
        method: 'get',
        responseType: 'blob', // 因为是流文件，所以要指定blob类型
        url: filePath
      }).then(({ data }) => {
        docxPre.renderAsync(data, this.$refs.word)
      })
    },
  }
}
</script>
<style scoped lang="less">
.title {
  font-size: 17px;
  color: #000000;
  font-weight: bold;
}
::v-deep .el-upload-list__item-file-name {
  width: 300px;
}
</style>
